<template>
  <div>
    <v-dialog
      class="custom-dialog text-center"
      transition="dialog-bottom-transition"
      max-width="450"
      v-model="this.$store.state.loaderDialog"
    >
      <h2 class="text-center my-5">
        Please wait while we are downloading the file
      </h2>
      <div class="text-center mb-5">
        <v-progress-circular
          :size="50"
          color="#000000DE"
          indeterminate
        ></v-progress-circular>
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "loadingDialog",
  methods: {
    close() {
      this.$store.commit("SET_LOADING_DIALOG", false);
    },
  },
};
</script>
<style lang="scss">
.v-dialog {
  background: #fff;
  padding: 25px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  .close {
    span {
      cursor: pointer;
    }
  }
}
</style>
<style lang="scss" scoped>
.custom-dialog {
}
</style>