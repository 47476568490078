<template>
  <div class="device-status">
    <div class="active">
      <span></span>Active -
      <strong>Device is activated and recieving the data continuously</strong>
    </div>
    <div class="awaiting">
      <span></span> Awaiting data -
      <strong
        >Device is activated and waiting to recieve first set of data</strong
      >
    </div>
    <div class="offline">
      <span></span>Offline -
      <strong>Device has not recieved any data from more than 3 hours</strong>
    </div>
    <div class="inactive">
      <span></span>Inactive - <strong>Device is not active</strong>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.device-status {
  font-size: 22px;
  font-weight: bold;
  .awaiting {
    // background-color: #9df0ff;
    border-radius: 10px;
    padding: 5px 20px;
    display: block;
    margin: 15px;
    margin-left: 0 !important;
    font-size: 14px;
    span {
      display: inline-block;
      width: 10px;
      height: 10px;
      background: #00788e;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
  .active {
    // background-color: #edfff0;
    border-radius: 10px;
    padding: 5px 20px;
    display: block;
    margin: 15px;
    margin-left: 0 !important;
    font-size: 14px;
    span {
      display: inline-block;
      width: 10px;
      height: 10px;
      background: #00be16;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
  .inactive {
    // background-color: #ffefef;
    border-radius: 10px;
    padding: 5px 20px;
    display: block;
    margin: 15px;
    margin-left: 0 !important;
    font-size: 14px;
    span {
      display: inline-block;
      width: 10px;
      height: 10px;
      background: #fd3332;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
  .offline {
    // background-color: #fff9e3;
    border-radius: 10px;
    padding: 5px 20px;
    display: block;
    margin: 15px;
    margin-left: 0 !important;
    font-size: 14px;
    span {
      display: inline-block;
      width: 10px;
      height: 10px;
      background: #fed230;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
}
</style>