<template>
  <div class="device-card">
    <v-row align="center">
      <v-col>
        <h2>{{ data.deviceName }}</h2>
        <h4>Device Id: {{ data.deviceNo }}</h4>
        <DeviceStatus :device="data" />
        <div class="device-loc">
          <v-icon class="mr-1" color="#E37D26">mdi-map-marker</v-icon>
          <span v-if="data.currentLocation != null">
            {{ data.currentLocation.locationName }}</span
          ><span v-else>N/A</span>
        </div>
      </v-col>
      <v-col class="text-center">
        <router-link :to="'/devices/' + data.deviceNo">
          <CustomButton :white="true" label="View Details" />
        </router-link>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CustomButton from "../common/CustomButton.vue";
import DeviceStatus from "./DeviceStatus.vue";
export default {
  props: ["data"],
  components: {
    CustomButton,
    DeviceStatus,
  },
};
</script>

<style lang="scss" scoped>
.device-card {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  padding: 50px;
  margin: 20px auto;
  max-width: 90%;
}
</style>