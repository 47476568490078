<template>
  <div class="header">
    <v-row justify="space-between" align="center">
      <v-col>
        <div class="ham" @click="openDrawer">
          <span></span><span></span><span></span>
        </div>
      </v-col>
      <v-col class="text-center">
        <router-link to="/"
          ><img
            src="/img/logo.svg"
            width="100%"
            style="max-width: 100px"
            class="pa-5"
            alt=""
        /></router-link>
      </v-col>
      <v-col class="text-right">
        <router-link to="/devices/scan"
          ><v-icon large color="#000"> mdi-qrcode-scan</v-icon></router-link
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Header",
  methods: {
    openDrawer() {
      this.$emit("openheader");
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  box-shadow: 0px 10px 30px #83838329;
  padding: 0 5%;
  .ham {
    display: inline-block;
    cursor: pointer;
    span {
      width: 40px;
      height: 2px;
      background: #000;
      display: block;
      margin: 10px 0;
    }
  }
}
</style>