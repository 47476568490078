var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"device-info"},[_c('div',{staticClass:"feature-img"},[_c('div',{staticClass:"device-status"},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',[_c('div',{staticClass:"device-name"},[_vm._v("Staff Management")]),_c('h3',[_vm._v(_vm._s(_vm.all.length)+" Users")])])],1)],1)]),_c('div',{staticClass:"device-info-details"},[_c('div',{staticClass:"staff-actions"},[_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('span',{on:{"click":_vm.addAdmin}},[_c('CustomButton',{attrs:{"white":true,"icon":"mdi-plus","label":"Add admin"}})],1)]),_c('v-col',{staticClass:"text-center"},[_c('span',{on:{"click":_vm.addStaff}},[_c('CustomButton',{attrs:{"white":true,"icon":"mdi-plus","label":"Add Staff"}})],1)])],1)],1),_c('v-tabs',[_c('v-tab',[_vm._v("Admin")]),_c('v-tab',[_vm._v("Staff")]),_c('v-tab-item',{attrs:{"transition":"fade-transition","reverse-transition":"fade-transition"}},[_c('v-card',[_c('v-card-title',[_c('v-spacer'),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.searchAdmin),callback:function ($$v) {_vm.searchAdmin=$$v},expression:"searchAdmin"}})],1),_c('v-data-table',{staticClass:"elevation-1 text-center",attrs:{"headers":_vm.headers,"items":_vm.admins,"items-per-page":15,"search":_vm.searchAdmin},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-3",attrs:{"color":"#418FBF"},on:{"click":function($event){return _vm.editItemAdmin(item)}}},[_vm._v(" mdi-lock-reset ")]),_c('v-icon',{attrs:{"color":"#DD2525"},on:{"click":function($event){return _vm.deleteItemAdmin(item.email)}}},[_vm._v(" mdi-trash-can-outline ")])]}},{key:"item.authorized",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{on:{"change":function($event){return _vm.authorizeAdmin(item._id, item.authorized)}},model:{value:(item.authorized),callback:function ($$v) {_vm.$set(item, "authorized", $$v)},expression:"item.authorized"}})]}}])})],1)],1),_c('v-tab-item',{attrs:{"transition":"fade-transition","reverse-transition":"fade-transition"}},[_c('v-card',[_c('v-card-title',[_c('v-spacer'),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.searchStaff),callback:function ($$v) {_vm.searchStaff=$$v},expression:"searchStaff"}})],1),_c('v-data-table',{staticClass:"elevation-1 text-center",attrs:{"headers":_vm.headers,"search":_vm.searchStaff,"items":_vm.staffs,"items-per-page":15},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-3",attrs:{"color":"#418FBF"},on:{"click":function($event){return _vm.editItemStaff(item)}}},[_vm._v(" mdi-lock-reset ")]),_c('v-icon',{attrs:{"color":"#DD2525"},on:{"click":function($event){return _vm.deleteItemStaff(item.email)}}},[_vm._v(" mdi-trash-can-outline ")])]}},{key:"item.authorized",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{on:{"change":function($event){return _vm.authorizeStaff(item._id, item.authorized)}},model:{value:(item.authorized),callback:function ($$v) {_vm.$set(item, "authorized", $$v)},expression:"item.authorized"}})]}}])})],1)],1)],1)],1),_c('AdminDialog',{on:{"added_admin":_vm.adminAdded}}),_c('StaffDialog',{on:{"added_staff":_vm.staffAdded}}),_c('EditUserDialog',{on:{"password_reset":_vm.passwordUpdated}}),_c('ConfirmDialog',{attrs:{"message":"Confirm to delete?"},on:{"confirmed":_vm.ConfirmDeleteStaff}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }