<template>
  <div>
    <v-dialog
      class="custom-dialog"
      transition="dialog-bottom-transition"
      max-width="450"
      v-model="this.$store.state.editUserDialog.status"
      @click:outside="close"
    >
      <div class="close text-right">
        <span @click="close"><img src="/img/x.svg" width="15" alt="" /></span>
      </div>
      <h2 class="text-center my-5">Add admin</h2>
      <v-text-field
        class="my-5 rounded-0"
        label="Enter username"
        type="text"
        color="#000000"
        ref="username"
        v-model="username"
        outlined
        required
        disabled
      ></v-text-field>
      <v-text-field
        class="my-5 rounded-0"
        label="Enter email"
        type="text"
        color="#000000"
        ref="email"
        v-model="email"
        outlined
        required
        disabled
      ></v-text-field>
      <v-text-field
        class="my-5 rounded-0"
        label="Enter password"
        type="text"
        color="#000000"
        ref="password"
        v-model="form.password"
        outlined
        required
      ></v-text-field>
      <div class="text-center my-5">
        <span @click="confirmed"
          ><CustomButton label="Submit" :white="true"
        /></span>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import CustomButton from "../common/CustomButton.vue";
export default {
  props: ["user", "type"],
  components: {
    CustomButton,
  },
  data() {
    return {
      form: {
        username: "",
        email: "",
        password: "",
      },
    };
  },
  computed: {
    username() {
      return this.$store.state.editUserDialog.form.username;
    },
    email() {
      return this.$store.state.editUserDialog.form.email;
    },
    usertype() {
      return this.$store.state.editUserDialog.type;
    },
  },
  mounted() {},
  methods: {
    close() {
      this.$store.commit("SET_EDIT_USER_DIALOG", false);
    },
    confirmed() {
      if (this.usertype == "admin") {
        this.$axios
          .put("/admin/user/resetadminpassword", {
            email: this.email,
            newpassword: this.form.password,
          })
          .then((res) => {
            if (res.status == 200) {
              this.$emit("password_reset");
              this.close();
            }
          })
          .catch((err) => console.log(err));
      } else {
        this.$axios
          .put("/admin/user/resetuserpassword", {
            email: this.email,
            newpassword: this.form.password,
          })
          .then((res) => {
            if (res.status == 200) {
              this.$emit("password_reset");
              this.close();
            }
          })
          .catch((err) => console.log(err));
      }
    },
  },
};
</script>
<style lang="scss">
.v-dialog {
  background: #fff;
  padding: 25px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  .close {
    span {
      cursor: pointer;
    }
  }
}
</style>
<style lang="scss" scoped>
.custom-dialog {
}
</style>