<template>
  <div class="device-list">
    <v-tabs v-model="activeTab">
      <v-tab :key="1">Active</v-tab>
      <v-tab :key="2">Inactive</v-tab>
      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <div v-if="active.length != 0">
          <DeviceCard v-for="(n, index) in active" :key="index" :data="n" />
        </div>

        <div class="text-center ma-5" v-else>
          <strong>No active devices</strong>
        </div>
      </v-tab-item>
      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <div v-if="inactive.length != 0">
          <DeviceCard v-for="(n, index) in inactive" :key="index" :data="n" />
        </div>
        <div class="text-center ma-5" v-else>
          <strong>No active devices</strong>
        </div>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import DeviceCard from "../components/devices/DeviceCard.vue";
export default {
  name: "Devices",
  components: {
    DeviceCard,
  },
  // watch: {
  //   $route(to) {
  //     this.activeTab = to.query.devicetype;
  //   },
  // },
  data() {
    return {
      devices: [],
      active: [],
      inactive: [],
      activeTab: 0,
    };
  },
  mounted() {
    this.activeTab = parseInt(this.$route.query.devicetype);
    this.getAllDevices();
  },
  methods: {
    getAllDevices() {
      this.$axios
        .get("device/admin/all")
        .then((res) => {
          this.devices = res.data.devices;
          this.active = this.devices.filter((item) => {
            return item.activated == true && item.logStatus == true;
          });
          this.inactive = this.devices.filter((item) => {
            return item.activated == false || item.logStatus == false;
          });
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style lang="scss" >
.device-list {
  margin-top: 80px;

  .v-slide-group__wrapper {
    border-bottom: 0px solid #e3e3e3 !important;
    max-width: 90%;
    margin: 15px auto;
  }
}
</style>