<template>
  <div class="device-info">
    <div class="feature-img">
      <div class="device-heading">
        <v-row justify="space-between">
          <v-col
            ><div class="device-name">{{ info.deviceName }}</div>
            <DeviceStatusTransparent :device="info" />
            <div class="deivce-loc" v-if="info.currentLocation != null">
              <v-icon class="mr-1" color="#fff">mdi-map-marker</v-icon>
              {{ info.currentLocation.locationName }}
            </div>
            <div class="device-loc" v-else>
              <v-icon class="mr-1" color="#fff">mdi-map-marker</v-icon> N/A
            </div>
            <div class="my-5" v-if="data.length !== 0">
              <span @click="downloadData()">
                <CustomButton
                  :white="true"
                  icon="mdi-table-arrow-down"
                  label="Download Data"
                />
              </span>
            </div>
          </v-col>
          <v-col class="text-right" align-self="end">
            <div v-if="info.logStatus">
              <span @click="stopLogging">
                <CustomButton :white="true" label="Stop Logging" />
              </span>

              <div class="log-started my-5">
                <strong>Started At :</strong>
                {{
                  info.logStartedAt.toLocaleString("en-US", {
                    timeZone: "Asia/Kolkata",
                  }) | moment("MMMM Do YYYY, h:mm:ss A")
                }}
              </div>
            </div>
            <div v-else>
              <span @click="startLogging">
                <CustomButton :white="true" label="Start Logging" />
              </span>
            </div>
            <div class="my-5">
              <router-link :to="'/devices/' + info.deviceNo">
                <CustomButton :transparent="true" label="Get Device Status" />
              </router-link>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="device-info-details">
      <v-card>
        <v-card-title>
          <div class="sync" v-if="info.logStatus">
            <span>Syncing</span>
            <v-icon color="#000" large class="rotate ml-3"
              >mdi-autorenew</v-icon
            >
          </div>
          <v-spacer></v-spacer>
          <span class="mr-5">Switch location to view history</span>
          <!-- <v-spacer></v-spacer> -->
          <v-select
            label="Location"
            color="#000000"
            :items="items"
            item-text="locationName"
            item-value="_id"
            v-model="switchLocation"
            @input="locationChanged"
            return-object
          >
          </v-select>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="data"
          :items-per-page="15"
          class="elevation-1 text-center"
          dense
          :mobile-breakpoint="0"
        >
          <template v-slot:item.data.p1="{ item }">
            <span v-if="item.data.p1 != null">{{ item.data.p1 }}</span>
            <span v-else>-</span>
          </template>
          <template v-slot:item.data.p25="{ item }">
            <span v-if="item.data.p25 != null">{{ item.data.p25 }}</span>
            <span v-else>-</span>
          </template>
          <template v-slot:item.data.p10="{ item }">
            <span v-if="item.data.p10 != null">{{ item.data.p10 }}</span>
            <span v-else>-</span>
          </template>
          <template v-slot:item.data.p100="{ item }">
            <span v-if="item.data.p100 != null">{{ item.data.p100 }}</span>
            <span v-else>-</span>
          </template>
          <template v-slot:item.data.w="{ item }">
            <span v-if="item.data.w != null">{{ item.data.w }}</span>
            <span v-else>-</span>
          </template>
          <template v-slot:item.time="{ item }">
            <v-icon color="#2CC9D6" class="mr-4"
              >mdi-database-clock-outline</v-icon
            >
            {{
              item.createdAt.toLocaleString("en-US", {
                timeZone: "Asia/Kolkata",
              }) | moment("from", "now")
            }}
          </template>
        </v-data-table>
      </v-card>
    </div>
    <!-- <SnackBar /> -->
  </div>
</template>

<script>
import CustomButton from "../common/CustomButton.vue";
// import SnackBar from "../common/SnackBar.vue";
import DeviceStatusTransparent from "./DeviceStatusTransparent.vue";
import Vue from "vue";
import FileDownload from "js-file-download";
// import moment from "vue-moment";
export default {
  // props: ["info", "data", "fileName", "currentLoc", "history"],
  props: {
    info: Object,
    data: Array,
    fileName: String,
    currentLoc: Object,
    history: Array,
  },
  components: {
    CustomButton,
    // SnackBar,
    DeviceStatusTransparent,
  },
  data() {
    return {
      // initInfo: Vue.util.extend({}, this.info),
      items: Vue.util.extend(this.history),
      switchLocation: Vue.util.extend({}, this.currentLoc),
      //   fileName:
      //     this.info.deviceName +
      //     "-" +
      //     this.$moment(new Date()).format("MMMM Do YYYY, h:mm:ss A"),
      date: new Date(),
      isReloading: false,
      headers: [
        {
          text: "PM 1",
          value: "data.p1",
          align: "center",
          width: "120px",
        },
        { text: "PM 2.5", value: "data.p25", align: "center", width: "120px" },
        { text: "PM 10", value: "data.p10", align: "center", width: "120px" },
        {
          text: "PM 100",
          value: "data.p100",
          align: "center",
          width: "120px",
        },
        {
          text: "Wind Speed",
          value: "data.w",
          align: "center",
          width: "150px",
        },
        { text: "Heading", value: "data.he", align: "center", width: "150px" },
        {
          text: "Temperature",
          value: "data.t",
          align: "center",
          width: "170px",
        },
        { text: "Humidity", value: "data.hu", align: "center", width: "150px" },
        { text: "Recorded", value: "time", align: "center", width: "180px" },
      ],
      jsonFields: {
        "Device Id": {
          field: "data.d",
          callback: (value) => {
            return `${value}`;
          },
        },
        Location: "location.locationName",
        Date: {
          field: "createdAt",
          callback: (value) => {
            return `${this.$moment(value).format("MMMM Do YYYY")}`;
          },
        },
        "Time in IST": {
          field: "createdAt",
          callback: (value) => {
            return `${this.$moment(value).format("h:mm:ss A")}`;
          },
        },
        "Heading + Offset": "data.he",
        "Wind Speed": "data.w",
        Temperature: "data.t",
        Humidity: "data.hu",
        "PM 1.0": "data.p1",
        "PM 2.5": "data.p25",
        "PM 10": "data.p10",
        "PM 1.0 * 1.47": {
          field: "data.p1",
          callback: (value) => {
            return `${value * 1.47}`;
          },
        },
        "PM 2.5 * 1.47": {
          field: "data.p25",
          callback: (value) => {
            return `${value * 1.47}`;
          },
        },
        "PM 10 * 1.47": {
          field: "data.p10",
          callback: (value) => {
            return `${value * 1.47}`;
          },
        },
      },
    };
  },
  computed: {
    formattedDate() {
      return this.$moment(this.date).format("MMMM Do YYYY, h:mm:ss A");
    },
  },
  mounted() {
    // debugger;
    // for (var i = 0; i <= this.info.locationHistory.length - 1; i++) {
    //   this.items.push({
    //     locationName: this.info.locationHistory[i].locationName,
    //     _id: this.info.locationHistory[i]._id,
    //   });
    // }
    // this.switchLocation = this.info.currentLocation;
    this.autoReload();
  },
  methods: {
    locationChanged() {
      this.$emit("location_changed", this.switchLocation);
    },
    autoReload() {
      setInterval(() => {
        this.$emit("data_changed");
        this.isReloading = true;
        setTimeout(() => {
          this.isReloading = false;
        }, 2000);
      }, 30000);
    },
    startLogging() {
      this.$axios
        .put("/device/admin/", {
          deviceNo: this.$route.params.id,
          logStatus: true,
          time: new Date().toISOString().replace(/T/, " ").replace(/\..+/, ""),
        })
        .then((res) => {
          if (res.status == 200) {
            this.$store.commit("SET_SNACKBAR", {
              active: true,
              message: "Device started",
            });
            this.$emit("data_changed");
          }
        })
        .catch((err) => console.log(err));
    },
    stopLogging() {
      this.$axios
        .put("/device/admin/", {
          deviceNo: this.$route.params.id,
          logStopped: true,
        })
        .then((res) => {
          if (res.status == 200) {
            this.$store.commit("SET_SNACKBAR", {
              active: true,
              message: "Device stopped ",
            });
            this.$emit("data_changed");
          }
        })
        .catch((err) => console.log(err));
    },
    syncAgain() {
      this.$emit("data_changed");
    },
    downloadData() {
      this.$store.commit("SET_LOADING_DIALOG", true);
      this.$axios
        .get(
          "/device/exportdata?deviceId=" +
            this.info._id +
            "&locationId=" +
            this.switchLocation._id
        )
        .then((res) => {
          // console.log(res, "Downloaded");
          var file =
            this.switchLocation.locationName +
            "-" +
            this.$moment(new Date()).format("MMMM Do YYYY, h:mm:ss A") +
            ".csv";
          FileDownload(res.data, file);
          this.$store.commit("SET_LOADING_DIALOG", false);
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>
<style lang="scss">
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.02);
}
table tbody tr td {
  font-size: 14px !important;
  padding: 15px !important;
}
table thead th {
  font-size: 16px !important;
  color: #000 !important;
  padding: 20px !important;
}
</style>
<style lang="scss" scoped>
@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.device-info {
  .feature-img {
    min-height: 350px;
    background: url(/img/praanbg.jpg), rgba(0, 0, 0, 0.7);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
    display: flex;
    align-items: center;
    .device-heading {
      max-width: 100%;
      flex: 0 0 100%;
      color: #fff;
      padding: 20px 4%;
      .device-name {
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 5px;
      }

      // .device-active {
      //   font-size: 26px;
      //   font-weight: bold;
      //   span.active {
      //     width: 15px;
      //     height: 15px;
      //     border-radius: 50%;
      //     background: #00be16;
      //     display: inline-block;
      //     vertical-align: middle;
      //     margin: 15px;
      //   }
      // }
      // .device-inactive {
      //   font-size: 26px;
      //   font-weight: bold;
      //   span.inactive {
      //     width: 15px;
      //     height: 15px;
      //     border-radius: 50%;
      //     background: red;
      //     display: inline-block;
      //     vertical-align: middle;
      //     margin: 15px;
      //   }
      // }
    }
  }
  .device-info-details {
    position: relative;
    max-width: 95%;
    margin: auto;
    top: -40px;
    .sync {
      .rotate {
        -webkit-animation: rotating 2s infinite;
        -moz-animation: rotating 2s infinite;
        -ms-animation: rotating 2s infinite;
        -o-animation: rotating 2s infinite;
        animation: rotating 2s infinite;
      }
    }
    .info-data {
      background: #fff;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 10px;
      max-width: 90%;
      margin: auto;
      padding: 50px;
      margin-bottom: 25px;
    }
  }
}
</style>