<template>
  <div>
    <DeviceDataTable
      :info="deviceInfo"
      :data="sensorData"
      :fileName="file"
      :currentLoc="deviceInfo.currentLocation"
      :history="items"
      @data_changed="getDeviceInfo"
      @location_changed="changeLocation"
    />
  </div>
</template>

<script>
import DeviceDataTable from "../components/devices/DeviceDataTable.vue";
export default {
  name: "DeviceData",
  components: {
    DeviceDataTable,
  },
  data() {
    return {
      deviceInfo: null,
      sensorData: null,
      file: null,
      items: [],
      location: null,
    };
  },
  mounted() {
    // this.getDeviceInfo();
    this.setItems();
  },
  methods: {
    changeLocation(switchLocation) {
      this.location = switchLocation;
      this.getDeviceData();
    },
    async setItems() {
      await this.getDeviceInfo();
      for (var i = 0; i <= this.deviceInfo.locationHistory.length - 1; i++) {
        this.items.push({
          locationName: this.deviceInfo.locationHistory[i].locationName,
          _id: this.deviceInfo.locationHistory[i]._id,
        });
      }
    },
    async getDeviceInfo() {
      await this.$axios
        .get("/device?deviceNo=" + this.$route.params.id)
        .then((res) => {
          this.deviceInfo = res.data.device;

          this.file =
            this.deviceInfo.currentLocation.locationName +
            "-" +
            this.$moment(new Date()).format("MMMM Do YYYY, h:mm:ss A") +
            ".csv";
          this.getDeviceData();
        })
        .catch((err) => console.log(err));
    },
    getDeviceData() {
      // + this.deviceInfo._id  for dynamic values
      if (this.deviceInfo.currentLocation != null) {
        if (this.location != null) {
          this.$axios
            .get(
              "device/sensordata?deviceId=" +
                this.deviceInfo._id +
                "&locationId=" +
                this.location._id
            )
            .then((res) => {
              this.sensorData = res.data.values;
            })
            .catch((err) => console.log(err));
        } else {
          this.$axios
            .get(
              "device/sensordata?deviceId=" +
                this.deviceInfo._id +
                "&locationId=" +
                this.deviceInfo.currentLocation._id
            )
            .then((res) => {
              this.sensorData = res.data.values;
            })
            .catch((err) => console.log(err));
        }
      } else {
        this.sensorData = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>