<template>
  <div>
    <DeviceInfo
      :data="deviceInfo"
      @data_changed="getDeviceInfo"
      :offset="deviceInfo.deviceOffset"
    />
  </div>
</template>

<script>
import DeviceInfo from "../components/devices/DeviceInfo.vue";
export default {
  name: "DeviceDetails",
  components: {
    DeviceInfo,
  },
  data() {
    return {
      deviceInfo: null,
    };
  },
  mounted() {
    this.getDeviceInfo();
  },
  methods: {
    getDeviceInfo() {
      this.$axios
        .get("/device?deviceNo=" + this.$route.params.id)
        .then((res) => {
          this.deviceInfo = res.data.device;
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style lang="scss" scoped>
</style>