<template>
  <div class="device-info">
    <div class="feature-img">
      <div class="device-status">
        <v-row justify="space-between">
          <v-col
            ><div class="device-name">Staff Management</div>
            <h3>{{ all.length }} Users</h3>
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="device-info-details">
      <div class="staff-actions">
        <v-row>
          <v-col class="text-center">
            <span @click="addAdmin"
              ><CustomButton :white="true" icon="mdi-plus" label="Add admin"
            /></span>
          </v-col>
          <v-col class="text-center">
            <span @click="addStaff"
              ><CustomButton
                :white="true"
                icon="mdi-plus"
                label="Add Staff" /></span
          ></v-col>
        </v-row>
      </div>
      <v-tabs>
        <v-tab>Admin</v-tab>
        <v-tab>Staff</v-tab>
        <v-tab-item
          transition="fade-transition"
          reverse-transition="fade-transition"
        >
          <v-card>
            <v-card-title>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchAdmin"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="admins"
              :items-per-page="15"
              :search="searchAdmin"
              class="elevation-1 text-center"
            >
              <template v-slot:item.actions="{ item }">
                <v-icon
                  class="mr-3"
                  color="#418FBF"
                  @click="editItemAdmin(item)"
                >
                  mdi-lock-reset
                </v-icon>
                <v-icon color="#DD2525" @click="deleteItemAdmin(item.email)">
                  mdi-trash-can-outline
                </v-icon>
              </template>
              <template v-slot:item.authorized="{ item }">
                <v-switch
                  v-model="item.authorized"
                  @change="authorizeAdmin(item._id, item.authorized)"
                ></v-switch>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
        <v-tab-item
          transition="fade-transition"
          reverse-transition="fade-transition"
        >
          <v-card>
            <v-card-title>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchStaff"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :search="searchStaff"
              :items="staffs"
              :items-per-page="15"
              class="elevation-1 text-center"
            >
              <template v-slot:item.actions="{ item }">
                <v-icon
                  class="mr-3"
                  color="#418FBF"
                  @click="editItemStaff(item)"
                >
                  mdi-lock-reset
                </v-icon>
                <v-icon color="#DD2525" @click="deleteItemStaff(item.email)">
                  mdi-trash-can-outline
                </v-icon>
              </template>
              <template v-slot:item.authorized="{ item }">
                <v-switch
                  v-model="item.authorized"
                  @change="authorizeStaff(item._id, item.authorized)"
                ></v-switch>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </div>
    <AdminDialog @added_admin="adminAdded" />
    <StaffDialog @added_staff="staffAdded" />
    <EditUserDialog @password_reset="passwordUpdated" />
    <ConfirmDialog
      message="Confirm to delete?"
      @confirmed="ConfirmDeleteStaff"
    />
    <!-- <SnackBar /> -->
  </div>
</template>

<script>
import CustomButton from "../common/CustomButton.vue";
import AdminDialog from "../dialogs/AdminDialog.vue";
import StaffDialog from "../dialogs/StaffDialog.vue";
import ConfirmDialog from "../dialogs/ConfirmDialog.vue";
import EditUserDialog from "../dialogs/EditUserDialog.vue";
// import SnackBar from "../common/SnackBar.vue";
export default {
  components: {
    CustomButton,
    AdminDialog,
    StaffDialog,
    ConfirmDialog,
    EditUserDialog,
    // SnackBar,
  },
  data() {
    return {
      searchAdmin: "",
      searchStaff: "",
      headers: [
        {
          text: "User name",
          value: "name",
          align: "center",
        },
        { text: "Email", value: "email", align: "center" },
        { text: "Authorized", value: "authorized" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      admins: [],
      staffs: [],
      editItem: "",
      all: [],
    };
  },
  mounted() {
    this.getAdmins();
    this.getUsers();
  },
  methods: {
    passwordUpdated() {
      this.$store.commit("SET_SNACKBAR", {
        active: true,
        message: "Password Updated Successfully",
      });
    },
    getAdmins() {
      this.$axios
        .get("/admin/all")
        .then((res) => {
          if (res.status == 200) {
            this.admins = res.data.users;
          }
        })
        .catch((err) => console.log(err));
    },
    getUsers() {
      this.$axios
        .get("/admin/user/all")
        .then((res) => {
          this.staffs = res.data.users;
          this.all = this.admins.concat(this.staffs);
        })
        .catch((err) => console.log(err));
    },
    addAdmin() {
      this.$store.commit("SET_ADMIN_DIALOG", true);
    },
    addStaff() {
      this.$store.commit("SET_STAFF_DIALOG", true);
    },
    authorizeAdmin(id, authorized) {
      this.$axios
        .put("/admin/", {
          id: id,
          authorized: authorized,
        })
        .then((res) => {
          console.log(res);
          this.$store.commit("SET_SNACKBAR", {
            active: true,
            message: "Admin Updated Successfully",
          });
          this.$emit("data_changed");
        })
        .catch((err) => console.log(err));
    },
    authorizeStaff(id, authorized) {
      this.$axios
        .put("/admin/user/", {
          id: id,
          authorized: authorized,
        })
        .then((res) => {
          console.log(res);
          this.$store.commit("SET_SNACKBAR", {
            active: true,
            message: "Staff Updated Successfully",
          });
          this.$emit("data_changed");
        })
        .catch((err) => console.log(err));
    },
    editItemAdmin(item) {
      this.$store.commit("SET_EDIT_USER_DIALOG", {
        status: true,
        user: item,
        type: "admin",
      });
    },
    editItemStaff(item) {
      this.$store.commit("SET_EDIT_USER_DIALOG", {
        status: true,
        user: item,
        type: "staff",
      });
    },
    deleteItemAdmin(email) {
      this.editUser = "admin";
      this.editItem = email;
      this.$store.commit("SET_CONFIRM_DIALOG", true);
    },
    deleteItemStaff(email) {
      this.editUser = "staff";
      this.editItem = email;
      this.$store.commit("SET_CONFIRM_DIALOG", true);
    },
    ConfirmDeleteStaff() {
      if (this.editUser == "staff") {
        this.$axios
          .delete("/admin/user/", {
            data: {
              email: this.editItem,
            },
          })
          .then((res) => {
            console.log(res);
            // this.$emit("data_changed");
            this.getUsers();
          })
          .catch((err) => console.log(err));
      } else {
        this.$axios
          .delete("/admin/", {
            data: {
              email: this.editItem,
            },
          })
          .then((res) => {
            console.log(res);
            // this.$emit("data_changed");
            this.getAdmins();
          })
          .catch((err) => console.log(err));
      }
    },
    staffAdded() {
      this.$store.commit("SET_SNACKBAR", {
        active: true,
        message: "Staff registered successfully",
      });
      // this.$emit("data_changed");
      this.getUsers();
    },
    adminAdded() {
      this.$store.commit("SET_SNACKBAR", {
        active: true,
        message: "Admin registered successfully",
      });
      this.$emit("data_changed");
    },
  },
};
</script>
<style lang="scss">
.v-tabs-bar {
  min-height: 48px !important;
  height: unset !important;
}
.v-tabs-bar__content {
  margin: 15px;
}
.v-tabs-slider-wrapper {
  display: none;
}
.v-tab {
  background: #fff !important;
  color: #000 !important;
  padding: 11px !important;
  margin-right: 15px !important;
  border-radius: 31px;
  border: 1px solid #000 !important;
}
.v-tab.v-tab--active {
  background: #000 !important;
  color: #fff !important;
  border: 1px solid transparent !important;
}
.v-slide-group__wrapper {
  border-bottom: 2px solid #e3e3e3 !important;
}
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.02);
}
table tbody tr td {
  font-size: 14px !important;
  padding: 15px !important;
}
table thead th {
  font-size: 16px !important;
  color: #000 !important;
  padding: 20px !important;
}
</style>
<style lang="scss" scoped>
.device-info {
  .feature-img {
    height: 350px;
    background: url(/img/praanbg.jpg), rgba(0, 0, 0, 0.6);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
    display: flex;
    align-items: center;
    .device-status {
      max-width: 100%;
      flex: 0 0 100%;
      color: #fff;
      padding: 50px;
      .device-name {
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 25px;
      }
      .device-active {
        font-size: 26px;
        font-weight: bold;
        span.active {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background: #00be16;
          display: inline-block;
          vertical-align: middle;
          margin: 15px;
        }
      }
      .device-inactive {
        font-size: 26px;
        font-weight: bold;
        span.inactive {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background: red;
          display: inline-block;
          vertical-align: middle;
          margin: 15px;
        }
      }
    }
  }
  .device-info-details {
    position: relative;
    max-width: 95%;
    margin: auto;
    background: #fff;
    top: -40px;
    .staff-actions {
      border-bottom: 2px solid #e3e3e3;
      .row {
        max-width: 550px;
        margin: auto;
        padding: 50px;
      }
    }
    .info-data {
      background: #fff;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 10px;
      max-width: 90%;
      margin: auto;
      padding: 50px;
      margin-bottom: 25px;
    }
  }
}
</style>