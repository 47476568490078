<template>
  <div>
    <v-dialog
      class="custom-dialog"
      transition="dialog-bottom-transition"
      max-width="450"
      v-model="this.$store.state.pairDialog"
      @click:outside="close"
    >
      <div class="close text-right">
        <span @click="close"><img src="/img/x.svg" width="15" alt="" /></span>
      </div>
      <h2 class="text-center my-5">Select a location to pair</h2>
      <v-form ref="form">
        <v-select
          label="Device location"
          outlined
          color="#000000"
          :items="items"
          item-text="locationName"
          ref="location"
          :rules="locationRules"
          item-value="_id"
          v-model="form.location"
          return-object
        >
        </v-select
      ></v-form>

      <div class="text-center my-5">
        <span @click="confirmed"
          ><CustomButton label="Submit" :white="true"
        /></span>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import CustomButton from "../common/CustomButton.vue";
export default {
  components: {
    CustomButton,
  },
  data() {
    return {
      locationRules: [(v) => !!v || "Location is required"],
      form: {
        location: null,
      },
      locations: [],
      items: [],
      formHasErrors: false,
    };
  },
  mounted() {
    this.getLocations();
  },
  methods: {
    getLocations() {
      this.$axios
        .get("/admin/location/all")
        .then((res) => {
          this.locations = res.data.locations;
          for (var i = 0; i <= res.data.locations.length - 1; i++) {
            this.items.push({
              locationName: res.data.locations[i].locationName,
              _id: res.data.locations[i]._id,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    close() {
      this.$store.commit("SET_PAIR_DIALOG", false);
    },
    confirmed() {
      //   var token = localStorage.getItem("user_token");
      //   var config = {
      //     headers: {
      //       "Content-Type": "application/json;charset=UTF-8",
      //       "Access-Control-Allow-Origin": "*",
      //       Authorization: `bearer ${token}`,
      //     },
      //   };
      const staff = localStorage.getItem("user");
      this.$axios
        .put("/device/admin/", {
          deviceNo: this.$route.params.id,
          pair: true,
          locationName: this.form.location.locationName,
          _id: this.form.location._id,
          deviceHandler: JSON.parse(staff),
        })
        .then((res) => {
          if (res.status == 200) {
            this.$refs.form.reset();
            this.$emit("paired");
            this.close();
          }
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>
<style lang="scss">
.v-dialog {
  background: #fff;
  padding: 25px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  .close {
    span {
      cursor: pointer !important;
    }
  }
}
</style>
<style lang="scss" >
.custom-dialog {
}
</style>