<template>
  <div class="side-menu">
    <div class="menu-header">
      <img src="/img/logo.svg" class="pa-3" width="100" alt="" />
    </div>
    <div class="menu-list">
      <v-list>
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-title>Device</v-list-item-title>
          </template>
          <v-list-item class="inner-nav">
            <router-link to="/pairnew"
              ><span @click="closeDrawer">Add a new device</span></router-link
            >
          </v-list-item>
          <!-- <v-list-item class="inner-nav">
            <router-link to="/searchpaired"
              ><span @click="closeDrawer"
                >Pair an existing device</span
              ></router-link
            >
          </v-list-item> -->
          <v-list-item class="inner-nav">
            <router-link to="/devices"
              ><span @click="closeDrawer">All devices</span></router-link
            >
          </v-list-item>
        </v-list-group>
        <v-list-item class="main-nav">
          <router-link to="/locations"
            ><span @click="closeDrawer">Location</span></router-link
          >
        </v-list-item>
        <v-list-item class="main-nav" v-if="access === 2">
          <router-link to="/staffs"
            ><span @click="closeDrawer">Staff management</span></router-link
          >
        </v-list-item>
        <v-list-item class="main-nav">
          <router-link to="/help"
            ><span @click="closeDrawer">Help</span></router-link
          >
        </v-list-item>
      </v-list>
    </div>
    <div class="user-info">
      <div class="username">{{ userName }}</div>
      <div class="logout">
        <router-link to="/myaccount">
          <v-icon color="#000" class="mr-2">mdi-account-key-outline</v-icon
          ><span @click="closeDrawer">Change password</span></router-link
        >
      </div>
      <div class="logout">
        <v-icon color="#000" class="mr-2">mdi-logout-variant</v-icon>
        <span @click="logout">Logout</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    userName() {
      var user = JSON.parse(localStorage.getItem("user"));
      var name = user.name;
      return name;
    },
    access() {
      var user = JSON.parse(localStorage.getItem("user"));
      var access_level = user.access_level;
      return access_level;
    },
  },
  data() {
    return {
      menu: [
        {
          id: 1,
          name: "Device",
          dropdown: [
            {
              id: 1,
              name: "Add a new device",
              link: "/",
            },
            {
              id: 2,
              name: "Pair an existing device",
              link: "/",
            },
            {
              id: 3,
              name: "All devices",
              link: "/all-devices",
            },
          ],
        },
        {
          id: 2,
          name: "Location",
          link: "/location",
        },
        {
          id: 3,
          name: "Staff Management",
          link: "/staff",
        },
      ],
    };
  },
  methods: {
    closeDrawer() {
      this.$emit("closedrawer");
    },
    logout() {
      localStorage.removeItem("user_token");
      localStorage.removeItem("user");
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="scss" scoped>
.side-menu {
  padding: 20px;
  .user-info {
    margin-top: 100px;
    border-top: 1px solid #e3e3e3;
    padding-top: 20px;
    .username {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 15px;
    }
    .logout {
      font-size: 18px;
      margin: 4px 0;
      font-weight: bold;
      span {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}
</style>