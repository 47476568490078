<template>
  <div>
    <v-dialog
      class="custom-dialog"
      transition="dialog-bottom-transition"
      max-width="450"
      v-model="this.$store.state.unpairDialog"
      @click:outside="close"
    >
      <div class="close text-right">
        <span @click="close"><img src="/img/x.svg" width="15" alt="" /></span>
      </div>
      <h2 class="text-center my-5">
        Are you sure you want to Unpair your device?
      </h2>
      <div class="text-center mb-5">
        <span @click="confirmed"
          ><CustomButton label="Confirm" :white="true"
        /></span>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import CustomButton from "../common/CustomButton.vue";
export default {
  components: {
    CustomButton,
  },
  methods: {
    close() {
      this.$store.commit("SET_UNPAIR_DIALOG", false);
    },
    confirmed() {
      const staff = localStorage.getItem("user");
      this.$axios
        .put("/device/admin/", {
          deviceNo: this.$route.params.id,
          unpair: true,
          deviceHandler: JSON.parse(staff),
        })
        .then((res) => {
          if (res.status == 200) {
            this.$emit("confirmed_Unpairing");
            this.close();
          }
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>
<style lang="scss">
.v-dialog {
  background: #fff;
  padding: 25px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  .close {
    span {
      cursor: pointer;
    }
  }
}
</style>
<style lang="scss" scoped>
.custom-dialog {
}
</style>