<template>
  <div>
    <v-dialog
      class="custom-dialog"
      transition="dialog-bottom-transition"
      max-width="450"
      v-model="this.$store.state.adminDialog"
      @click:outside="close"
    >
      <div class="close text-right">
        <span @click="close"><img src="/img/x.svg" width="15" alt="" /></span>
      </div>
      <h2 class="text-center my-5">Add admin</h2>
      <v-text-field
        class="my-5 rounded-0"
        label="Enter username"
        type="text"
        color="#000000"
        ref="username"
        v-model="form.username"
        outlined
        required
      ></v-text-field>
      <v-text-field
        class="my-5 rounded-0"
        label="Enter email"
        type="text"
        color="#000000"
        ref="email"
        v-model="form.email"
        outlined
        required
      ></v-text-field>
      <v-text-field
        class="my-5 rounded-0"
        label="Enter password"
        type="text"
        color="#000000"
        ref="password"
        v-model="form.password"
        outlined
        required
      ></v-text-field>
      <div class="text-center my-5">
        <span class="text-decoration-underline" @click="generateP"
          >GENERATE RANDOM PASSWORD</span
        >
      </div>
      <div class="text-center my-5">
        <span @click="confirmed"
          ><CustomButton label="Submit" :white="true"
        /></span>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import CustomButton from "../common/CustomButton.vue";
export default {
  components: {
    CustomButton,
  },
  data() {
    return {
      form: {
        username: "",
        email: "",
        password: "",
      },
    };
  },
  methods: {
    close() {
      this.$store.commit("SET_ADMIN_DIALOG", false);
    },
    generateP() {
      var pass = "";
      var str =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZ" +
        "abcdefghijklmnopqrstuvwxyz0123456789@#$";

      for (var i = 1; i <= 8; i++) {
        var char = Math.floor(Math.random() * str.length + 1);

        pass += str.charAt(char);
      }

      this.form.password = pass;
    },
    confirmed() {
      this.$axios
        .post("/admin/signup", {
          name: this.form.username,
          email: this.form.email,
          password: this.form.password,
        })
        .then((res) => {
          if (res.status == 200) {
            this.$emit("added_admin");
            this.close();
          }
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>
<style lang="scss">
.v-dialog {
  background: #fff;
  padding: 25px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  .close {
    span {
      cursor: pointer;
    }
  }
}
</style>
<style lang="scss" scoped>
.custom-dialog {
}
</style>