<template>
  <div
    class="custom-snackbar"
    :class="this.$store.state.snackBar.color"
    v-if="this.$store.state.snackBar.active"
  >
    <h5>{{ this.$store.state.snackBar.message }}</h5>
  </div>
</template>

<script>
export default {
  name: "SnackBar",
};
</script>

<style lang="scss" scoped>
.custom-snackbar.success {
  background: #00be16;
}
.custom-snackbar.error {
  background: #fd3332;
}
.custom-snackbar {
  position: fixed;
  top: 110px;
  right: -300px;
  padding: 15px;
  color: #fff;
  background: #00be16;
  -webkit-animation: slide 0.5s forwards;
  -webkit-animation-delay: 0.2s;
  animation: slide 0.5s forwards;
  animation-delay: 0.2s;
  min-width: 300px;
}
@-webkit-keyframes slide {
  100% {
    right: 0;
  }
}

@keyframes slide {
  100% {
    right: 0;
  }
}
</style>