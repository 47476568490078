<template>
  <div>
    <v-dialog
      class="custom-dialog"
      transition="dialog-bottom-transition"
      max-width="450"
      v-model="this.$store.state.confirmDialog"
      @click:outside="close"
      @keydown="close"
    >
      <div class="close text-right">
        <span @click="close"><img src="/img/x.svg" width="15" alt="" /></span>
      </div>
      <h2 class="text-center my-5">
        {{ message }}
      </h2>
      <div class="text-center mb-5">
        <span @click="confirmed"
          ><CustomButton label="Confirm" :white="true"
        /></span>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import CustomButton from "../common/CustomButton.vue";
export default {
  name: "ConfirmDialog",
  props: ["message"],
  components: {
    CustomButton,
  },
  methods: {
    close() {
      this.$store.commit("SET_CONFIRM_DIALOG", false);
    },
    confirmed() {
      this.$emit("confirmed");
      this.close();
    },
  },
};
</script>
<style lang="scss">
.v-dialog {
  background: #fff;
  padding: 25px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  .close {
    span {
      cursor: pointer;
    }
  }
}
</style>
<style lang="scss" scoped>
.custom-dialog {
}
</style>