import axios from 'axios';
import router from '../router/index'
import Store from '../store/index'
// let config = {
//     baseURL: 'http://localhost:8900/v1'
// }
let config = {
    baseURL: 'https://ses.praan.io/api'
}

const instance = axios.create(config);
let getToken = () => {
    return localStorage.getItem('user_token')
}

instance.interceptors.request.use(
    config => {
        // Add or remove auth header before request is sent
        const token = getToken()
        if (token) {
            config.headers.common['Authorization'] = 'bearer ' + token
        } else {
            delete config.headers.common['Authorization']
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)
instance.interceptors.response.use(function (response) {
    return response
}, function (error) {
    console.log(error.response.data)
    if (error.response.status === 401) {
        Store.commit('SET_SNACKBAR', {
            active: true,
            message: error.response.data.message || error.response.data.error,
            color: 'error'
        })
        localStorage.removeItem('user_token')
        localStorage.removeItem('user')
        if (router.app._route.path != '/login') {
            router.push('/login')
        }
    } else if (error.response.status == 400) {
        Store.commit('SET_SNACKBAR', {
            active: true,
            message: error.response.data.message || error.response.data.error,
            color: 'error'
        })
    }
    return Promise.reject(error)
})

export default {
    install: function (Vue) {
        Object.defineProperty(Vue.prototype, '$axios', { value: instance });
    }
}