<template>
  <div class="device-info">
    <div class="feature-img">
      <div class="device-heading">
        <v-row justify="space-between">
          <v-col
            ><div class="device-name">
              {{ data.deviceName }}
              <v-icon class="ml-3" color="#418FBF" @click="editName">
                mdi-pencil-outline
              </v-icon>
            </div>
            <DeviceStatusTransparent :device="data" />
          </v-col>
          <v-col
            class="text-right"
            align-self="end"
            v-if="data.currentLocation != null"
          >
            <router-link :to="'/devices/data/' + data.deviceNo">
              <CustomButton :transparent="true" label="Get Device Info" />
            </router-link>
          </v-col>
          <v-col class="text-right" align-self="end" v-else>
            <span @click="pairDeviceError">
              <CustomButton :transparent="true" label="Get Device Info" />
            </span>
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="device-info-details">
      <div class="info-data">
        <v-row justify="space-between">
          <v-col>
            <h4 class="mb-5">Paired to</h4>
            <h3>
              <v-icon class="mr-1" color="#E37D26">mdi-map-marker</v-icon>
              <span v-if="data.currentLocation != null">
                {{ data.currentLocation.locationName }}</span
              ><span v-else>N/A</span>
            </h3></v-col
          >
          <v-col class="text-right" align-self="end">
            <span @click="unpair" v-if="data.currentLocation != null">
              <CustomButton :white="true" label="Unpair" />
            </span>
            <span @click="pair" v-else>
              <CustomButton :white="true" label="Pair" />
            </span>
          </v-col>
        </v-row>
      </div>
      <div class="info-data">
        <v-row justify="space-between">
          <v-col>
            <h4 class="mb-5">Updated by</h4>
            <h3>
              <v-icon class="mr-1" color="#864291">mdi-account</v-icon>
              {{ data.deviceHandler.name }}
            </h3></v-col
          >
        </v-row>
      </div>
      <div class="info-data">
        <v-row justify="space-between">
          <v-col>
            <h4 class="mb-5">Last Push</h4>
            <v-row>
              <v-col cols="12" xl="6" lg="6" md="6"
                ><div class="date" v-if="data.lastPushAt != null">
                  <v-icon color="#2CC9D6" class="mr-4"
                    >mdi-calendar-month-outline</v-icon
                  >
                  {{
                    data.lastPushAt.toLocaleString("en-US", {
                      timeZone: "Asia/Kolkata",
                    }) | moment("MMMM Do YYYY")
                  }}
                </div>
                <div class="date" v-else>
                  <v-icon color="#2CC9D6" class="mr-4"
                    >mdi-calendar-month-outline</v-icon
                  >
                  N/A
                </div></v-col
              >
              <v-col cols="12" xl="6" lg="6" md="6"
                ><div class="time" v-if="data.lastPushAt != null">
                  <v-icon color="#2CC9D6" class="mr-4"
                    >mdi-database-clock-outline</v-icon
                  >
                  {{
                    data.lastPushAt.toLocaleString("en-US", {
                      timeZone: "Asia/Kolkata",
                    }) | moment("from", "now")
                  }}
                  <span
                    ><strong>At: </strong>
                    {{
                      data.lastPushAt.toLocaleString("en-US", {
                        timeZone: "Asia/Kolkata",
                      }) | moment("h:mm:ss A")
                    }}</span
                  >
                </div>
                <div class="time" v-else>
                  <v-icon color="#2CC9D6" class="mr-4"
                    >mdi-database-clock-outline</v-icon
                  >
                  N/A
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <div class="info-data">
        <v-row justify="space-between">
          <v-col>
            <h4 class="mb-5">Started logging at</h4>
            <v-row>
              <v-col cols="12" xl="6" lg="6" md="6"
                ><div class="date" v-if="data.logStartedAt != null">
                  <v-icon color="#2CC9D6" class="mr-4"
                    >mdi-calendar-month-outline</v-icon
                  >
                  {{
                    data.logStartedAt.toLocaleString("en-US", {
                      timeZone: "Asia/Kolkata",
                    }) | moment("MMMM Do YYYY")
                  }}
                </div>
                <div class="date" v-else>
                  <v-icon color="#2CC9D6" class="mr-4"
                    >mdi-calendar-month-outline</v-icon
                  >
                  N/A
                </div>
              </v-col>
              <v-col cols="12" xl="6" lg="6" md="6"
                ><div class="time" v-if="data.logStartedAt != null">
                  <v-icon color="#2CC9D6" class="mr-4"
                    >mdi-database-clock-outline</v-icon
                  >
                  {{
                    data.logStartedAt.toLocaleString("en-US", {
                      timeZone: "Asia/Kolkata",
                    }) | moment("from", "now")
                  }}
                  <span
                    ><strong>At: </strong>
                    {{
                      data.logStartedAt.toLocaleString("en-US", {
                        timeZone: "Asia/Kolkata",
                      }) | moment("h:mm:ss A")
                    }}</span
                  >
                </div>
                <div class="time" v-else>
                  <v-icon color="#2CC9D6" class="mr-4"
                    >mdi-database-clock-outline</v-icon
                  >
                  N/A
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <div class="info-data">
        <v-row justify="space-between">
          <v-col cols="12" xl="6" lg="6" md="6">
            <h3>Offset</h3>
            <v-row align="center">
              <v-col>
                <v-text-field
                  class="my-5 rounded-0"
                  label="Offset value"
                  type="number"
                  color="#000000"
                  ref="offset"
                  :value="data.deviceOffset"
                  v-model="form.offset"
                  outlined
                  required
                ></v-text-field>
              </v-col>
              <v-col>
                <span class="text-decoration-underline" @click="updateOffset"
                  >UPDATE</span
                >
              </v-col>
            </v-row>
          </v-col>
          <v-col
            class="qr-img"
            id="DeviceQRCode"
            align-self="end"
            cols="12"
            xl="6"
            lg="6"
            md="6"
          >
            <v-row justify="center" align="end">
              <v-col cols="12" class="text-center"
                ><qr-code
                  :text="data.deviceNo.toString()"
                  :size="150"
                  color="#000000"
                  bg-color="#ffffff"
                  error-level="L"
                  v-model="data.deviceNo"
                >
                </qr-code>
                <span class="hide">Device Id : {{ data.deviceNo }}</span> <br />
              </v-col>
              <v-col cols="12" class="text-center">
                <span class="text-decoration-underline" @click="printQr">
                  Print QR
                </span></v-col
              >
            </v-row>
          </v-col>
        </v-row>
      </div>
    </div>
    <UnpairDialog @confirmed_Unpairing="unpairDevice" />
    <PairDialog @paired="pairedDevice" />
    <ConfirmDialog
      message="Confirm to change offset value?"
      @confirmed="changeOffset"
    />
    <EditDeviceDialog
      :name="data.deviceName"
      :deviceNo="data.deviceNo"
      @name_updated="nameUpdate"
    />
  </div>
</template>

<script>
import CustomButton from "../common/CustomButton.vue";
import UnpairDialog from "../dialogs/UnpairDialog.vue";
import PairDialog from "../dialogs/PairDialog.vue";
import ConfirmDialog from "../dialogs/ConfirmDialog.vue";
import DeviceStatusTransparent from "./DeviceStatusTransparent.vue";
import EditDeviceDialog from "../dialogs/EditDeviceName.vue";
export default {
  props: ["data", "offset"],
  components: {
    CustomButton,
    UnpairDialog,
    PairDialog,
    ConfirmDialog,
    DeviceStatusTransparent,
    EditDeviceDialog,
  },
  data() {
    return {
      form: {
        offset: "",
      },
    };
  },
  mounted() {
    if (this.offset != null) {
      this.form.offset = this.offset;
    } else {
      this.form.offset = "";
    }
  },
  methods: {
    editName() {
      if (this.data.logStatus == false) {
        this.$store.commit("SET_EDIT_DEVICE_DIALOG", true);
      } else {
        this.$store.commit("SET_ALERT_DIALOG", {
          status: true,
          message: "Please stop the device before updating name",
        });
      }
    },
    unpair() {
      if (this.data.logStatus == false) {
        this.$store.commit("SET_UNPAIR_DIALOG", true);
      } else {
        this.$store.commit("SET_ALERT_DIALOG", {
          status: true,
          message: "Please stop the device before unpairing",
        });
      }
    },
    pair() {
      this.$store.commit("SET_PAIR_DIALOG", true);
    },
    pairDeviceError(){
      this.$store.commit("SET_SNACKBAR", {
              active: true,
              message: "Device not paired to any location",
              color:'error'
            });
    },
    updateOffset() {
      if (this.data.logStatus == false) {
        this.$store.commit("SET_CONFIRM_DIALOG", true);
      } else {
        this.$store.commit("SET_ALERT_DIALOG", {
          status: true,
          message: "Please stop the device before updating offset",
        });
      }
    },
    nameUpdate(deviceId) {
      this.$store.commit("SET_SNACKBAR", {
        active: true,
        message: "Device name updated successfully",
      });
      if (deviceId != null) {
        this.$router.push("/devices/" + deviceId);
      }
      this.$emit("data_changed");
    },
    changeOffset() {
      this.$axios
        .put("/device/admin/", {
          deviceNo: this.$route.params.id,
          offset: this.form.offset,
        })
        .then((res) => {
          if (res.status == 200) {
            this.$store.commit("SET_SNACKBAR", {
              active: true,
              message: "Device offset updated successfully",
            });
            this.$emit("data_changed");
            // if (this.offset != null) {
            //   this.form.offset = this.offset;
            // } else {
            //   this.form.offset = "";
            // }
          }
        })
        .catch((err) => console.log(err));
    },
    unpairDevice() {
      this.$store.commit("SET_SNACKBAR", {
        active: true,
        message: "Device Unpaired",
      });
      this.$emit("data_changed");
    },
    pairedDevice() {
      this.$store.commit("SET_SNACKBAR", {
        active: true,
        message: "Device Paired to Selected Location",
      });
      this.$emit("data_changed");
    },
    printQr() {
      this.$htmlToPaper("DeviceQRCode");
    },
  },
};
</script>
<style lang="scss">
.qr-img {
  img {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
</style>
<style lang="scss" scoped>
.device-info {
  .feature-img {
    min-height: 350px;
    background: url(/img/praanbg.jpg), rgba(0, 0, 0, 0.6);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
    display: flex;
    align-items: center;
    .device-heading {
      max-width: 100%;
      flex: 0 0 100%;
      color: #fff;
      padding: 20px 4%;
      .device-name {
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 5px;
      }
    }
    //   .device-status {
    //     max-width: 100%;
    //     flex: 0 0 100%;
    //     color: #fff;
    //     padding: 50px;
    //     .device-name {
    //       font-size: 22px;
    //       font-weight: bold;
    //       margin-bottom: 25px;
    //     }
    //     .device-active {
    //       font-size: 26px;
    //       font-weight: bold;
    //       span.active {
    //         width: 15px;
    //         height: 15px;
    //         border-radius: 50%;
    //         background: #00be16;
    //         display: inline-block;
    //         vertical-align: middle;
    //         margin: 15px;
    //       }
    //     }
    //     .device-inactive {
    //       font-size: 26px;
    //       font-weight: bold;
    //       span.inactive {
    //         width: 15px;
    //         height: 15px;
    //         border-radius: 50%;
    //         background: red;
    //         display: inline-block;
    //         vertical-align: middle;
    //         margin: 15px;
    //       }
    //     }
    //   }
  }
  .device-info-details {
    position: relative;
    top: -40px;
    .info-data {
      background: #fff;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 10px;
      max-width: 90%;
      margin: auto;
      padding: 50px;
      margin-bottom: 25px;
    }
    .hide {
      display: none;
    }
  }
}
</style>