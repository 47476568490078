<template>
  <div class="custom-container">
    <h1>FAQs</h1>
    <div class="qna">
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header class="font-weight-bold"
            >How to add a device ?</v-expansion-panel-header
          >
          <v-expansion-panel-content class="my-5">
            <p>
              <strong
                >1. Go to the side menu and click on add a new device.</strong
              >
            </p>
            <img src="/img/add.jpg" alt="" />
            <p>
              <strong
                >2. Fill in the respective fields such as Device Id, Device
                name, Location(optional) and submit.</strong
              >
            </p>
            <img src="/img/pair.jpg" alt="" />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="font-weight-bold"
            >How to add a location ?</v-expansion-panel-header
          >
          <v-expansion-panel-content class="my-5">
            <p>
              <strong>1. Go to the side menu and click on location.</strong>
            </p>
            <img src="/img/loc.jpg" alt="" />
            <p>
              <strong
                >2. Click on add a new location button, Fill in the location
                name and submit.</strong
              >
            </p>
            <img src="/img/addloc.jpg" alt="" />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="font-weight-bold"
            >How to pair a device to a location ?</v-expansion-panel-header
          >
          <v-expansion-panel-content class="my-5">
            <p>
              <strong
                >1. You can click on the top right Qr scanner icon and then scan
                the QR code printed on the device.</strong
              >
            </p>
            <img src="/img/qr.jpg" alt="" />
            <p>
              <strong
                >2. Once scanned It will take you to the device info page, Click
                on pair</strong
              >
            </p>
            <img src="/img/pairing.jpg" alt="" />
            <p>
              <strong
                >3. and select the location you want to pair device with. Make
                sure the location is already added to the system, if not
                navigate to the the <a href="/locations">locations page</a> and
                add a new location.</strong
              >
            </p>
            <img src="/img/sloc.jpg" alt="" />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="font-weight-bold"
            >How to change offset value for a device ?</v-expansion-panel-header
          >
          <v-expansion-panel-content class="my-5">
            <p>
              <strong
                >1. You can click on the top right Qr scanner icon and then scan
                the QR code printed on the device.Once scanned It will take you
                to the device info page.</strong
              >
            </p>
            <img src="/img/qr.jpg" alt="" />
            <p>
              <strong
                >2. Navigate to bottom of the page you can see the offset field.
                Enter the calculated offset value displayed on the device and
                update.</strong
              >
            </p>
            <img src="/img/offset.jpg" alt="" />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="font-weight-bold"
            >How to unpair a device from a location?</v-expansion-panel-header
          >
          <v-expansion-panel-content class="my-5">
            <p>
              <strong
                >1. You can click on the top right Qr scanner icon and then scan
                the QR code printed on the device.Once scanned It will take you
                to the device info page.</strong
              >
            </p>
            <img src="/img/qr.jpg" alt="" />
            <p>
              <strong
                >2. Before clicking on unpair, make sure the you have stopped
                the device and downloaded the previous data dump.</strong
              >
            </p>
            <img src="/img/unpair.jpg" alt="" />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="font-weight-bold"
            >How to download data for a device ?</v-expansion-panel-header
          >
          <v-expansion-panel-content class="my-5">
            <p>
              <strong
                >1. You can click on the top right Qr scanner icon and then scan
                the QR code printed on the device.Once scanned It will take you
                to the device info page.</strong
              >
            </p>
            <img src="/img/qr.jpg" alt="" />
            <p>
              <strong
                >2. Click on Get Device Info button, you will navigated to the
                populated data page for the device.</strong
              >
            </p>
            <img src="/img/datatable.jpg" alt="" />
            <p>
              <strong
                >3. To download the data dump till now, click on Download data
                button.</strong
              >
            </p>
            <img src="/img/download.jpg" alt="" />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="font-weight-bold"
            >How to start a device ?</v-expansion-panel-header
          >
          <v-expansion-panel-content class="my-5">
            <p>
              <strong
                >1. You can click on the top right Qr scanner icon and then scan
                the QR code printed on the device.Once scanned It will take you
                to the device info page.</strong
              >
            </p>
            <img src="/img/qr.jpg" alt="" />
            <p>
              <strong
                >2. Make sure you have paired the device to a location and the
                final offset value is updated.</strong
              >
            </p>
            <p>
              <strong
                >3. Then you can click on the Get Device Info button, once
                navigated click on start logging button to start the
                device.</strong
              >
            </p>
            <img src="/img/datatable.jpg" alt="" />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="font-weight-bold"
            >How to stop a device ?</v-expansion-panel-header
          >
          <v-expansion-panel-content class="my-5">
            <p>
              <strong
                >1. You can click on the top right Qr scanner icon and then scan
                the QR code printed on the device.Once scanned It will take you
                to the device info page.</strong
              >
            </p>
            <img src="/img/qr.jpg" alt="" />
            <p>
              <strong
                >2. Then you can click on the Get Device Info button, once
                navigated click on stop logging button to stop the device.
              </strong>
            </p>
            <img src="/img/datatable.jpg" alt="" />
            <p>
              <strong
                >3. Before that make sure you have downloaded the data till
                now.</strong
              >
            </p>
            <img src="/img/stop.jpg" alt="" />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="font-weight-bold"
            >How to change your account password ?</v-expansion-panel-header
          >
          <v-expansion-panel-content class="my-5">
            <p>
              <strong
                >1. Go to the side menu and click on change password.</strong
              >
            </p>
            <p>
              <strong
                >2. Enter the Old password(Generated by admin), then enter a new
                password and click on update.</strong
              >
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="font-weight-bold"
            >Different device status and what it means
            ?</v-expansion-panel-header
          >
          <v-expansion-panel-content class="my-5">
            <DeviceStatusNote />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
import DeviceStatusNote from "./devices/DeviceStatusNote.vue";
export default {
  components: {
    DeviceStatusNote,
  },
};
</script>
<style lang="scss">
.theme--light.v-expansion-panels .v-expansion-panel {
  //   background-color: #f4f4f4 !important;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 20px;
  padding: 10px;
}
.theme--light.v-expansion-panels.v-expansion-panels--focusable
  .v-expansion-panel-header:hover:before {
  opacity: 0 !important;
}
</style>
<style lang="scss" scoped>
.custom-container {
  max-width: 90%;
  margin: 25px auto;
  h1 {
    text-align: center;
    margin: 50px 0 !important;
  }
  .qna {
    img {
      margin: 15px;
      padding: 15px;
      border: 0.75px solid #e3e3e3;
      max-width: 100%;
    }
  }
}
</style>