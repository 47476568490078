<template>
  <div class="edit-profile">
    <h2>Change password</h2>
    <v-spacer />
    <v-form ref="form">
      <v-text-field
        class="my-5 rounded-0"
        label="Name"
        type="text"
        color="#000000"
        ref="name"
        :rules="nameRules"
        v-model="form.name"
        disabled
        outlined
        required
      ></v-text-field>
      <v-text-field
        class="my-5 rounded-0"
        label="Email"
        type="text"
        color="#000000"
        ref="email"
        v-model="user.email"
        disabled
        outlined
        required
      ></v-text-field>
      <v-text-field
        class="my-5 rounded-0"
        label="Enter old password"
        type="text"
        color="#000000"
        ref="oldpassword"
        v-model="form.oldpassword"
        outlined
        required
      ></v-text-field>
      <v-text-field
        class="my-5 rounded-0"
        label="Enter new password"
        type="text"
        color="#000000"
        ref="newpassword"
        v-model="form.newpassword"
        outlined
        required
      ></v-text-field>
      <div class="text-center my-5">
        <span @click="submit"> <CustomButton label="Update" /></span>
      </div>
    </v-form>
    <!-- <SnackBar /> -->
  </div>
</template>

<script>
import CustomButton from "../common/CustomButton.vue";
// import SnackBar from "../common/SnackBar.vue";
export default {
  name: "MyAccount",
  components: {
    CustomButton,
    // SnackBar,
  },
  data() {
    return {
      nameRules: [(v) => !!v || "Name is required"],
      passwordRules: [(v) => !!v || "Password is required"],
      form: {
        name: "",
        oldpassword: "",
        newpassword: "",
      },
      user: null,
      formHasErrors: false,
    };
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.form.name = this.user.name;
  },
  methods: {
    async submit(e) {
      e.preventDefault();
      this.formHasErrors = false;

      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) this.formHasErrors = true;
        this.$refs["name"].validate(true);
        this.$refs["newpassword"].validate(true);

        // console.log(this.form[f]);
      });

      if (this.formHasErrors == false) {
        if (this.user.access_level == 2) {
          this.$axios
            .put("/admin/changepassword", {
              email: this.user.email,
              oldpassword: this.form.oldpassword,
              newpassword: this.form.newpassword,
            })
            .then((res) => {
              console.log(res);
              if (res.status == 200) {
                this.$store.commit("SET_SNACKBAR", {
                  active: true,
                  message: "Password Updated successfully",
                });
                this.$refs.form.reset();
              }
            });
        } else {
          this.$axios
            .put("/user/changepassword", {
              email: this.user.email,
              oldpassword: this.form.oldpassword,
              newpassword: this.form.newpassword,
            })
            .then((res) => {
              console.log(res);
              if (res.status == 200) {
                this.$store.commit("SET_SNACKBAR", {
                  active: true,
                  message: "Password Updated successfully",
                });
                this.$refs.form.reset();
              }
            });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-profile {
  max-width: 550px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 21px;
  padding: 50px;
  margin-top: 120px !important;
  margin: auto;
}
</style>