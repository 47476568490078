<template>
  <div class="device-status">
    <div class="inactive" v-if="!device.activated || !device.logStatus"><span></span>Inactive</div>
    <div
      class="awaiting"
      v-else-if="
        device.logStatus == true &&
        device.currentLocation !== null &&
        device.lastPushData == null &&
        offline == false
      "
    >
      <span></span> Awaiting data
    </div>
    <div
      class="active"
      v-else-if="
        device.lastPushData != null &&
        device.currentLocation !== null &&
        device.logStatus == true &&
        offline == false
      "
    >
      <span></span>Active
    </div>
    <div
      class="offline"
      v-else-if="
        device.logStatus == true &&
        device.lastPushAt != null &&
        device.lastPushData != null &&
        offline == true
      "
    >
      <span></span>Offline
    </div>
  </div>
</template>

<script>
export default {
  props: ["device"],
  data() {
    return {
      timeDiff: "",
      offline: false,
      currentDate: new Date(),
    };
  },
  mounted() {
    var duration = this.$moment.duration(
      this.$moment(this.currentDate).diff(this.device.lastPushAt)
    );
    var minutes = duration.asMinutes();
    if (minutes > 180) {
      this.offline = true;
    } else {
      this.offline = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.device-status {
  font-size: 22px;
  font-weight: bold;
  .awaiting {
    // background-color: #9df0ff;
    border-radius: 10px;
    padding: 5px 20px;
    display: inline-block;
    margin: 15px;
    margin-left: 0 !important;
    font-size: 14px;
    span {
      display: inline-block;
      width: 10px;
      height: 10px;
      background: #00788e;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
  .active {
    // background-color: #edfff0;
    border-radius: 10px;
    padding: 5px 20px;
    display: inline-block;
    margin: 15px;
    margin-left: 0 !important;
    font-size: 14px;
    span {
      display: inline-block;
      width: 10px;
      height: 10px;
      background: #00be16;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
  .inactive {
    // background-color: #ffefef;
    border-radius: 10px;
    padding: 5px 20px;
    display: inline-block;
    margin: 15px;
    margin-left: 0 !important;
    font-size: 14px;
    span {
      display: inline-block;
      width: 10px;
      height: 10px;
      background: #fd3332;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
  .offline {
    // background-color: #fff9e3;
    border-radius: 10px;
    padding: 5px 20px;
    display: inline-block;
    margin: 15px;
    margin-left: 0 !important;
    font-size: 14px;
    span {
      display: inline-block;
      width: 10px;
      height: 10px;
      background: #fed230;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
}
</style>