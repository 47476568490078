<template>
  <v-app>
    <div class="app-container" v-if="this.default">
      <vue-drawer-layout
        ref="drawerLayout"
        :drawer-width="300"
        :enable="true"
        :animatable="true"
        :z-index="0"
        :drawable-distance="Math.floor(800 / 3)"
        :content-drawable="true"
        :backdrop="true"
        :backdrop-opacity-range="[0, 0.4]"
        @mask-click="handleMaskClick"
      >
        <div class="drawer" slot="drawer">
          <SideMenu @closedrawer="handleToggleDrawer" />
        </div>
        <div class id="content-wrap" slot="content">
          <Header @openheader="handleToggleDrawer" />
          <transition name="page" mode="out-in">
            <router-view />
          </transition>
        </div>
      </vue-drawer-layout>
    </div>
    <div v-else class="app-no-header">
      <transition name="page" mode="out-in">
        <router-view />
      </transition>
    </div>
    <Snackbar />
    <AlertDialog />
    <LoaderDialog />
  </v-app>
</template>

<script>
import Header from "./components/header/Header.vue";
import SideMenu from "./components/drawer/SideMenu.vue";
import Snackbar from "./components/common/SnackBar.vue";
import AlertDialog from "./components/dialogs/AlertDialog.vue";
import LoaderDialog from "./components/dialogs/loaderDialog.vue";
export default {
  name: "App",
  components: {
    Header,
    SideMenu,
    Snackbar,
    AlertDialog,
    LoaderDialog,
  },
  data: () => ({
    //
    default: true,
  }),
  watch: {
    $route() {
      if (this.$route.meta.layout == "no-header") {
        this.default = false;
      } else {
        this.default = true;
      }
    },
  },
  mounted() {
    if (this.$route.meta.layout == "no-header") {
      this.default = false;
    } else {
      this.default = true;
    }
  },
  methods: {
    handleMaskClick() {
      this.$refs.drawerLayout.toggle(false);
    },
    handleToggleDrawer(e) {
      this.$refs.drawerLayout.toggle(e);
    },
  },
};
</script>
<style lang="scss">
a {
  color: inherit !important;
  text-decoration: none !important;
}
html {
  overflow: hidden !important;
}
body {
  overflow: hidden !important;
}
.v-application--wrap {
  overflow: hidden !important;
}

.content-wrap {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
.drawer-wrap {
  background: #fff;
}
.drawer-layout {
  background: #ffffff !important;
}
.app-no-header {
  background: #ffffff;
  width: 100%;
  margin: auto;
}
.app-container {
  .drawer-wrap {
    background: #fff;
  }
  .drawer-layout {
    background: #ffffff;
  }
  .content-wrap {
    overflow-y: auto !important;
    overflow-x: hidden !important;
  }
  #content-wrap {
    background: #ffffff;
    // max-width: 1024px;
    // margin: auto;
    // padding: 15px;
  }
}
</style>
