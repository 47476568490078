<template>
  <div class="login-page">
    <div class="login-gradient">
      <div class="login-content">
        <router-link to="/"
          ><img src="/img/logo.svg" width="120" alt="Praan Logo"
        /></router-link>
      </div>
      <div class="login-form">
        <v-form>
          <v-text-field
            class="my-5"
            label="Email"
            type="email"
            color="#000000"
            ref="email"
            :rules="emailRules"
            v-model="form.email"
            required
          ></v-text-field>
          <v-text-field
            class="my-5"
            label="Password"
            :rules="passwordRules"
            color="#000000"
            ref="password"
            v-model="form.password"
            type="password"
            required
          ></v-text-field>
          <v-select
            class="my-5"
            label="Access"
            ref="access"
            :items="access_options"
            :rules="accessRules"
            v-model="form.access"
            required
          >
          </v-select>
          <div class="text-center my-5">
            <span @click="login"> <CustomButton label="Login" /></span>
          </div>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from "../common/CustomButton.vue";
export default {
  components: {
    CustomButton,
  },
  data() {
    return {
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      passwordRules: [(v) => !!v || "Password is required"],
      accessRules: [(v) => !!v || "Access is required"],
      formHasErrors: false,
      form: {
        email: "",
        password: "",
        access: null,
      },
      access_options: [
        {
          value: 1,
          text: "Technician",
        },
        {
          value: 2,
          text: "Admin",
        },
      ],
    };
  },
  methods: {
    async login(e) {
      e.preventDefault();
      this.formHasErrors = false;

      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) this.formHasErrors = true;
        this.$refs[f].validate(true);
        console.log(this.form[f]);
      });
      if (this.formHasErrors == false) {
        console.log("Form Values", this.form);
        if (this.form.access == 2) {
          this.$axios
            .post("/admin/signin", {
              email: this.form.email,
              password: this.form.password,
            })
            .then((res) => {
              console.log(res);
              if (res.data.message == "Login successful" && res.status == 200) {
                localStorage.setItem("user_token", res.data.token);
                localStorage.setItem("user", JSON.stringify(res.data.user));
                this.$store.commit("SET_USER", res.data.user);
                this.$router.push("/");
              } else {
                console.log("Something went Wrong");
              }
            })
            .catch((err) => console.log(err));
        } else if (this.form.access == 1) {
          this.$axios
            .post("/user/signin", {
              email: this.form.email,
              password: this.form.password,
            })
            .then((res) => {
              console.log(res);
              if (res.data.message == "Login successful" && res.status == 200) {
                localStorage.setItem("user_token", res.data.token);
                localStorage.setItem("user", JSON.stringify(res.data.user));
                this.$store.commit("SET_USER", res.data.user);
                this.$router.push("/");
              } else {
                console.log("Something went Wrong");
              }
            })
            .catch((err) => console.log(err));
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-page {
  height: 100vh;
  background: url(/img/praanbg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  .login-gradient {
    background: transparent
      linear-gradient(
        180deg,
        #ffffff 0%,
        #fffffff0 69%,
        #f2f2f2c4 99%,
        #e8edea7e 100%
      )
      0% 0% no-repeat padding-box;

    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .login-content {
      margin-bottom: 60px;
    }
    .login-form {
      max-width: 550px;
      margin: 15px;
      width: 80%;
      // margin: auto;
    }
  }
}
</style>