<template>
  <div>
    <v-dialog
      class="custom-dialog"
      transition="dialog-bottom-transition"
      max-width="450"
      v-model="this.$store.state.locationDialog"
      @click:outside="close"
    >
      <div class="close text-right">
        <span @click="close"><img src="/img/x.svg" width="15" alt="" /></span>
      </div>
      <h2 class="text-center my-5">Add a new location</h2>
      <v-text-field
        class="my-5 rounded-0"
        label="Enter location name"
        type="text"
        color="#000000"
        ref="location"
        v-model="form.location"
        outlined
        required
      ></v-text-field>
      <div class="text-center my-5">
        <span @click="confirmed"
          ><CustomButton label="Submit" :white="true"
        /></span>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import CustomButton from "../common/CustomButton.vue";
export default {
  components: {
    CustomButton,
  },
  data() {
    return {
      form: {
        location: "",
      },
    };
  },
  methods: {
    close() {
      this.$store.commit("SET_LOCATION_DIALOG", false);
    },
    confirmed() {
      //   var token = localStorage.getItem("user_token");
      //   var config = {
      //     headers: {
      //       "Content-Type": "application/json;charset=UTF-8",
      //       "Access-Control-Allow-Origin": "*",
      //       Authorization: `bearer ${token}`,
      //     },
      //   };
      this.$axios
        .post("/admin/location/add", {
          locationName: this.form.location,
        })
        .then((res) => {
          if (res.status == 200) {
            this.$emit("added_location");
            this.close();
          }
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>
<style lang="scss">
.v-dialog {
  background: #fff;
  padding: 25px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  .close {
    span {
      cursor: pointer !important;
    }
  }
}
</style>
<style lang="scss" >
.custom-dialog {
}
</style>